import React from 'react';
import '../../App.css';

class Home extends React.Component {
  componentDidMount() {
    this.props.cs();
  }
  
  render() {
    return (
      <>
        
      </>
    );
  }
}

export default Home;
